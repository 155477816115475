html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

*:focus {
	outline: none;
}

a {
	color: inherit; /* blue colors for links too */
	text-decoration: inherit; /* no underline */
}

*,
*::before,
*::after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	/* Normalmente o valor padrão é 16px sendo assim 62.5% passa a ser 10px  */
	font-size: 62.5%;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100vw;
	min-height: 100vh;
	margin: 0;
	padding: 0;
}

body {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	min-height: 100vh;
	margin: 0;
	padding: 0;
}

p,
span,
label,
input,
textarea {
	font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
	font-family: 'M PLUS Rounded 1c', sans-serif;
}

/* .awssld__wrapper {
	max-height: 60rem !important;
} */
