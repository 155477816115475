.aws-btn {
    --organic-arrow-color: #ffffff;
}

.awssld__controls__arrow-right::before {
    background-color: #ffffff!important;
}
.awssld__controls__arrow-right::after {
    background-color: #ffffff!important;
}
.awssld__controls__arrow-left::before {
    background-color: #ffffff!important;
}
.awssld__controls__arrow-left::after {
    background-color: #ffffff!important;
}